import Vue from 'vue'
import Vuex from 'vuex'
import { config } from './config/config.mjs'
import { fetchGET, fetchPOST } from "./js/functions"
import { wsConnect } from './js/wsController.js'
import { router } from './router.js'
Vue.use(Vuex)
export const store = new Vuex.Store({
  state: {
    theme: "light",
    ws: null,
    wsId: null,
    loggedIn: false,
    wsConnected: false,
    dataLoaded: true,
    searchResults: [],
    isElectron: false,
    snack: { snackBar: false, snackColor: "", snackText: "", timeout: 3000 },
    printers: [],
    settings: {
      company: {
        logo: "/img/logo.png",
        name: ""
      },
      quoteColumns: [
        {
          "width": "*",
          "text": "Product",
          "value": "name",
          "type": "text"
        },
        {
          "width": 200,
          "text": "Omschrijving",
          "value": "description",
          "type": "text"
        },
        {
          "width": "auto",
          "text": "Aantal",
          "value": "amount",
          "type": "quantity"
        },
        {
          "width": "auto",
          "text": "Prijs",
          "value": "price",
          "type": "currency"
        },
        {
          "width": "auto",
          "text": "Subtotaal",
          "value": "netSubtotal",
          "type": "currency"
        }
      ],
      orderColumns: [
        {
          "width": "*",
          "text": "Artikel",
          "value": "name",
          "type": "text"
        },
        {
          "width": 200,
          "text": "Omschrijving",
          "value": "description",
          "type": "text"
        },
        {
          "width": "auto",
          "text": "Aantal",
          "value": "amount",
          "type": "quantity"
        },
        {
          "width": "auto",
          "text": "Inhoud",
          "value": "unitAmount",
          "type": "quantity"
        },
        {
          "width": "auto",
          "text": "Prijs",
          "value": "price",
          "type": "currency"
        },
        {
          "width": "auto",
          "text": "Subtotaal",
          "value": "netSubtotal",
          "type": "currency"
        },
        {
          "width": "auto",
          "text": "Lotnr",
          "value": "lotNumber",
          "type": "quantity"
        },
      ],
      invoiceColumns: [
        {
          "width": "*",
          "text": "Artikel",
          "value": "name",
          "type": "text"
        },
        {
          "width": 200,
          "text": "Omschrijving",
          "value": "description",
          "type": "text"
        },
        {
          "width": "auto",
          "text": "Aantal",
          "value": "amount",
          "type": "quantity"
        },
        {
          "width": "auto",
          "text": "Inhoud",
          "value": "unitAmount",
          "type": "quantity"
        },
        {
          "width": "auto",
          "text": "Prijs",
          "value": "price",
          "type": "currency"
        },
        {
          "width": "auto",
          "text": "Subtotaal",
          "value": "netSubtotal",
          "type": "currency"
        },
        {
          "width": "auto",
          "text": "Lotnr",
          "value": "lotNumber",
          "type": "quantity"
        },
      ],
      quoteHeaders: [
        { text: "Status", value: "status", width: 150 },
        { text: "Offertenr", value: "quoteNumber" },
        { text: "Ordernr", value: "orderNumber" },
        { text: "Naam", value: "name" },
        { text: "Datum", value: "date" },
        { text: "Route", value: "route", width: 150 },
        { text: "Stad", value: "client.city" },
        { text: "Totaal", value: "grossTotal" },
        { text: "Print", value: "printed", width: 50, sortable: false },
        { text: "Email", value: "emailed", width: 50, sortable: false },
        { text: "Kaart", value: "maps", width: 50, sortable: false },
        { text: "", value: "actions", width: 110, sortable: false }
      ],
      orderHeaders: [
        { text: "Status", value: "status", width: 150 },
        { text: "Ordernr", value: "orderNumber" },
        { text: "Factuur", value: "invoiceNumber" },
        { text: "Naam", value: "name" },
        { text: "Datum", value: "date" },
        { text: "Route", value: "route", width: 150 },
        { text: "Stad", value: "client.city" },
        { text: "Totaal", value: "grossTotal" },
        { text: "Print", value: "printed", width: 50, sortable: false },
        { text: "Email", value: "emailed", width: 50, sortable: false },
        { text: "Kaart", value: "maps", width: 50, sortable: false },
        { text: "", value: "actions", width: 110, sortable: false }
      ],
      invoiceHeaders: [
        { text: "Status", value: "status", width: 150 },
        { text: "Factuur", value: "invoiceNumber" },
        { text: "Naam", value: "name" },
        { text: "Datum", value: "date" },
        { text: "Stad", value: "client.city" },
        { text: "Totaal", value: "grossTotal" },
        { text: "Openstaand", value: "debit" },
        { text: "Print", value: "printed", width: 50, sortable: false },
        { text: "Email", value: "emailed", width: 50, sortable: false },
        { text: "", value: "actions", width: 110, sortable: false }
      ],
      orderPrinter: { name: "", pageSize: "", copies: 2 },
      invoicePrinter: { name: "", pageSize: "", copies: 2, stationary: false },
      labelPrinter: { name: "", pageSize: "", copies: 2 },
      VAT: [
        { name: "Verlegd", amount: false },
        { name: "Geen", amount: 0 },
        { name: "Laag", amount: 9 },
        { name: "Hoog", amount: 21 }
      ],
      categories: [
      ],
      purchaseCategories: [],
      routes: [],
      "quoteStatuses": [
        {
          "status": 0,
          "color": "red",
          "text": "Nieuw",
          "length": 0
        },
        {
          "status": 1,
          "color": "orange",
          "text": "In behandeling",
          "length": 0
        },
        {
          "status": 2,
          "color": "light-green",
          "text": "Voltooid",
          "length": 0
        },
        {
          "status": 3,
          "color": "black",
          "text": "Gefactureerd",
          "length": 0
        }
      ],
      "orderStatuses": [
        {
          "status": 0,
          "color": "red",
          "text": "Nieuw",
          'length': 0
        },
        {
          "status": 1,
          "color": "orange",
          "text": "In behandeling",
          "length": 0
        },
        {
          "status": 2,
          "color": "light-green",
          "text": "Voltooid",
          "length": 0
        },
        {
          "status": 3,
          "color": "black",
          "text": "Gefactureerd",
          "length": 0
        }
      ],
      "invoiceStatuses": [
        {
          "status": 0,
          "color": "red",
          "text": "Nieuw",
          "length": 0
        },
        {
          "status": 1,
          "color": "orange",
          "text": "Openstaand",
          "length": 0
        },
        {
          "status": 2,
          "color": "light-green",
          "text": "Betaald",
          "length": 0
        }
      ],
      searchClient: {
        showContact: false
      },
      payMethods: ["Pin", "Bank", "Kas", "iDEAL"],
      ideal: {
        enabled: false,
      },
      invoiceTemplate: 'default_template',
      invoiceTemplates: [
        { text: "Standaard", value: "default_template" },
        { text: "Sjabloon 1", value: "template1" },
        { text: "Sjabloon 2", value: "template2" },
        { text: "Sjabloon 3", value: "template3" },
      ],
      packaging: [
        { name: "Krat", type: "Crate" }, { name: "Doos", type: "Box" }, { name: "Vacuum", type: "Sealed" }
      ]
    },
    client: { scope: 0 },
    selectedClient: {},
    selectedSupplier: {},
    employee: {},
    employees: [],
    quotes: [],
    quotesLength: 0,
    orders: [],
    ordersLength: 0,
    invoices: [],
    totalInvoices: 0,
    clients: [],
    totalClients: 0,
    items: [],
    totalItems: 0,
    totalItemsSold: [],
    recent: [],
    clientDataLoaded: false,
    fixedPrices: [],
    VAT: [
      { name: "Verlegd", amount: false },
      { name: "Geen", amount: 0 },
      { name: "Laag", amount: 9 },
      { name: "Hoog", amount: 21 }
    ],
    clientOrders: [],
    openOrders: [],
    outstandingInvoices: [],
    cashCredit: 0,
    pickItems: [],
    pickupOrders: [],
    active: "Recent",
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0],
    categories: [
      "Kip"
    ],
    payMethods: ["Pin", "Bank", "Kas", "iDEAL"],//TODO: add to settings
    progress: 0,
    vehicles: [],
    drivers: [],
    logistics: [],
    purchaseOrders: [],
    purchaseInvoices: [],
  },
  mutations: {
    setTheme: (state, val) => state.theme = val,
    setWs: (state, val) => state.ws = val,
    loggedIn: (state, val) => state.loggedIn = val,
    wsConnected: (state, val) => {
      state.wsConnected = val
      if (state.wsConnected) {
        state.snack = { snackBar: true, success: true, snackText: "Verbonden" }
        setTimeout(() => {
          state.snack = { snackBar: false }
        }, 3000);


      }
      else {
        state.snack = { snackBar: true, error: true, snackText: "Niet verbonden" }
      }
    }
    ,
    dataLoaded: (state, val) => {
      state.dataLoaded = val
      state.progress = 0
    },
    setProgress: (state, val) => {
      state.progress = val
    },
    snack: (state, val) => {
      let timeout = val.timeout || 3000
      if (timeout) {
        state.snack = val
        if (val.snackBar) {
          timeout = setTimeout(() => {
            state.snack.snackBar = false
            timeout = null
          }, timeout);
        }
      }
    },
    setPrinters: (state, val) => {
      state.printers = val
    },
    isElectron: (state, val) => state.isElectron = val,
    settings: (state, val) => Object.assign(state.settings, val),
    updateLabelSettings: (state, val) => state.settings.labelPrinter = val,
    setClient: (state, val) => {
      state.client = val
    },
    selectClient: (state, val) => state.selectedClient = val,
    selectSupplier: (state, val) => state.selectedSupplier = val,
    setEmployee: (state, val) => state.employee = val,
    setEmployees: (state, val) => state.employees = val,
    fetchQuotes: (state, val) => {
      state.quotes = val[0].results
    },
    fetchOrders: (state, val) => {
      state.orders = val[0].results
    },
    fetchInvoices: (state, val) => {
      state.invoices = val[0].results
    },
    clientOrders: (state, val) => state.orders = val[0].results,
    clientInvoices: (state, val) => state.invoices = val[0].results,
    pickItem: (state, val) => state.pickItems.push(val),
    fetchPickupOrders: (state, val) => state.pickupOrders = val,
    insertPickupOrder: (state, val) => state.pickupOrders.push(val),
    incrementPickupOrderStatus: (state, val) => {
      let index = state.pickupOrders.findIndex(order => order._id === val._id)
      if (index > -1) state.pickupOrders[index].status++
    },
    resetPickupOrderStatus: (state, val) => {
      let index = state.pickupOrders.findIndex(order => order._id === val._id)
      if (index > -1) state.pickupOrders[index].status = 0
    },
    deletePickupOrder: (state, val) => {
      let index = state.pickupOrders.findIndex(order => order._id === val._id)
      if (index > -1) state.pickupOrders.splice(index, 1)
    },
    fetchClients: (state, val) => {
      state.clients = val.clients
      state.totalClients = val.length
    },
    openOrders: (state, val) => {
      state.openOrders = val
    },
    outstandingInvoices: (state, val) => state.outstandingInvoices = val,
    cashCredit: (state, val) => {
      let totalAmount = val.length ? val[0].totalAmount : 0
      state.cashCredit = totalAmount
    },
    fetchItems: (state, val) => {
      state.items = val.items
      state.totalItems = val.length
    },
    totalItemsSold(state, val) {
      state.totalItemsSold = val
    },
    setRoutes: (state, val) => state.routes = val,
    setRecent: (state, val) => {
      console.log(val)
      state.recent = val
    },
    clientDataLoaded: (state, val) => {
      state.clientDataLoaded = val
      state.recent.length && !state.clientDataLoaded ? state.active = "Recent" : state.active = "Kip"
    },
    clientFixedPrices: (state, val) => {
      state.fixedPrices = val
      state.clientDataLoaded = true
    },
    insertOrderFromQuote: (state, val) => {
      let date = new Date(val.deliveryDate).toISOString().split("T")[0]
      if (date == state.today) state.orders.push(val)
      for (let quote of state.quotes) {
        if (quote.quoteNumber == val.quoteNumber) {
          quote.orderNumber = val.orderNumber
          quote.status = 2
          quote.selectable = false
        }
      }
    },
    insertOrder: (state, val) => {
      let date = new Date(val.date).toISOString().split("T")[0]
      if (date == state.today) state.orders.push(val)
    },
    insertInvoice(state, val) {
      for (let order of val.orders) {
        for (let order2 of state.orders) {
          if (order._id == order2._id) {
            order2.invoiceNumber = val.invoiceNumber
            order2.status = 3
            order2.selectable = false
            order2.items.forEach(item => item.status = 2)
          }
        }
      }
      state.invoices.push(val)
    },
    updateInvoice: (state, val) => {
      for (let invoice of state.invoices) {
        if (invoice._id === val._id) {
          Object.assign(invoice, val)
          return
        }
      }
    },
    incInvoice: (state, val) => {
      for (let invoice of state.invoices) {
        if (invoice._id === val._id) {
          invoice.status = val.status
          return
        }
      }
    },
    deleteInvoice(state, val) {
      let i = 0
      let len = state.invoices.length
      for (i; i < len; i++) {
        if (state.invoices[i]._id == val) {
          state.invoices.splice(i, 1)
          return
        }
      }
    },
    deleteOrder(state, val) {
      console.log(' deleteOrder mutation')
      console.log(val)
      let i = 0
      let len = state.orders.length
      for (i; i < len; i++) {
        if (state.orders[i]._id == val._id) {
          state.orders.splice(i, 1)
          return
        }
      }
    },
    assignItem(state, val) {
      state.orders.forEach(order => {
        order.items.forEach(item => {
          if (item.name == val.item.name) item.assigned = val.item.assigned
        })
      })
    },
    assignOrder(state, val) {
      state.orders.forEach(order => {
        if (order._id == val._id) order.assigned = val.assigned
      })
    },
    updateOrderItem: (state, val) => {
      for (let order of state.orders) {
        if (order._id === val.order._id) {
          for (let item of order.items) {
            if (item._id === val.item._id) {
              console.log("object assigned")
              Object.assign(item, val.item)
              return
            }
          }
        }
      }
      if (state.pickupOrders.length) {
        state.pickupOrders.forEach(order => {
          let index = order.items.findIndex(item => item._id == val._id)
          if (index > -1) order.items.splice(index, 1)
        })
      }
    },
    updateOrder: (state, val) => {
      for (let order of state.orders) {
        if (order._id === val._id) {
          Object.assign(order, val)
          return
        }
      }
    },
    deleteOrderItem: (state, val) => {
      for (let order of state.orders) {
        if (order._id === val.order_id) {
          for (let i = 0; i < order.items.length; i++) {
            if (order.items[i]._id === val.item_id) {
              order.items.splice(i, 1)
              return
            }
          }
        }
      }
    },
    outOfStock: (state, val) => {
      for (let order of state.orders) {
        for (let item of order.items) {
          if (item.name == val.name) {
            item.amount = 0
            item.netSubtotal = 0
            item.totalVAT = 0
            item.totalProfit = 0
            item.totalCost = 0
            item.grossSubtotal = 0
            item.status = 2
          }
        }
      }
    },
    pushItems: (state, val) => {
      for (let order of state.orders) {
        if (order._id === val._id) {
          for (let item of val.items) {
            for (let orderItem of order.items) {
              if (orderItem._id == item._id && orderItem.unit == item.unit) {
                orderItem.amount += item.amount
                orderItem.netSubtotal += item.netSubtotal

                orderItem.grossSubtotal += item.grossSubtotal

                orderItem.totalCost += item.totalCost
                orderItem.totalProfit += item.totalProfit

              }
              else order.items.push(item)
            }

          }
          order.notes = val.notes
          return
        }
      }
    },
    incItemStatus: (state, val) => {
      for (let order of state.orders) {
        if (order._id === val.order_id) {
          for (let item of order.items) {
            if (item._id === val._id) {
              console.log(val)
              let status = (item.status > 2 && item.status <= order.status) ? 2 : val.status
              item.status = status
              return
            }
          }
        }
      }
    },
    orderStatus: (state, val) => {
      for (let order of state.orders) {
        if (order._id === val._id) {
          order.status = val.status
          return
        }
      }
    },
    incOrderStatus: (state, val) => {
      for (let order of state.orders) {
        if (order._id === val._id) {
          order.status = val.status
          for (let item of order.items) {
            if (item.status > -1 && item.status < val.status) item.status = val.status
          }
          return
        }
      }
    },
    sortOrders: (state, val) => {
      for (let order of state.orders) {
        for (let sort of val) {
          if (order._id === sort._id) {
            order.sort = sort.sort
          }
        }
      }
    },
    orderLabelPrinted: (state, val) => {
      for (let order of state.orders) {
        if (order._id === val._id) {
          for (let item of order.items) {
            for (let selected of val.items) {
              if (item._id === selected._id) {
                item.status = 4
              }
            }
          }
          return
        }
      }
    },
    insertClient(state, val) {
      state.clients.push(val)
    },
    updateClient(state, val) {
      console.log(val.name)
      for (let client of state.clients) {
        if (client._id === val._id) {
          Object.assign(client, val)
          return
        }
      }
    },
    deleteClient(state, val) {
      let i = 0
      let len = state.clients.length
      let clients = state.searchResults ? state.searchResults : state.clients
      for (i; i < len; i++) {
        if (clients[i]._id === val) {
          clients.splice(i, 1)
          return
        }
      }
    },
    insertItem(state, val) {
      state.items.push(val)
    },
    updateItem(state, val) {
      for (let item of state.items) {
        if (item._id === val._id) {
          Object.assign(item, val)
          // item.name = val.name
          // item.price = val.price
          // item.unit = val.unit
          // item.category = val.category
          return
        }
      }
    },
    deleteItem(state, val) {
      let i = 0
      let len = state.items.length
      let items = state.items
      for (i; i < len; i++) {
        if (items[i]._id === val) {
          items.splice(i, 1)
          return
        }
      }
    },
    insertFixedPrice(state, val) {
      for (let item of state.items) {
        if (item.itemNumber == val.itemNumber) {
          if (item.fixedPrices && item.fixedPrices.length) item.fixedPrices.push(val)
          state.fixedPrices.push(val)
          return
        }
      }
    },
    updateFixedPrice(state, val) {
      for (let item of state.items) {
        if (item.itemNumber == val.itemNumber) {
          if (item.fixedPrices && item.fixedPrices.length) {
            for (let fixed of item.fixedPrices) {
              if (fixed.clientNumber == val.clientNumber) {
                val.updated = new Date()
                Object.assign(fixed, val)
                return
              }
            }
          }
        }
      }
      console.log("continue")
      for (let fixed of state.fixedPrices) {
        if (fixed.itemNumber == val.itemNumber) Object.assign(fixed, val)
        return
      }
    },
    deleteFixedPrice(state, val) {
      for (let item of state.items) {
        if (item.itemNumber == val.itemNumber) {
          if (item.fixedPrices && item.fixedPrices.length) {
            let i = 0
            let len = item.fixedPrices.length
            for (i; i < len; i++) {
              if (item.fixedPrices[i].clientNumber == val.clientNumber) {
                item.fixedPrices.splice(i, 1)
                return
              }
            }
          }
          break
        }
      }
      let index = state.fixedPrices.findIndex(fixed => fixed.itemNumber == val.itemNumber)
      if (index > -1) {
        state.fixedPrices.slice(index, 1)
        state.fixedPrices.length = 0
        console.log("slice")
        console.log(index)
      }
    },
    setActive(state, val) {
      state.active = val
    },
    username(state, val) {
      state.username = val
    },
    insertRoute(state, val) {
      state.routes.push(val)
    },
    deleteRoute(state, val) {
      let index = state.routes.findIndex(route => route == val)
      if (index > -1) state.routes.splice(index, 1)
    },
    orderPrinted(state, val) {
      let index = state.orders.findIndex(order => order._id == val._id)
      if (index > -1) {
        state.orders[index].printed = val.printed
        state.orders[index].status = val.status
        state.orders[index].items.forEach(item => item.status = val.status)
      }
    },
    ordersPrinted(state, orders) {
      for (let order of orders) {
        let index = state.orders.findIndex(obj => obj.orderNumber == order.orderNumber)
        if (index > -1) {
          state.orders[index].printed = true
          state.orders[index].status = order.status
          state.orders[index].items.forEach(item => item.status = order.status)
        }
      }
    },
    orderEmailed(state, val) {
      let index = state.orders.findIndex(order => order._id == val._id)
      if (index > -1) {
        state.orders[index].emailed = val.emailed
        state.orders[index].status = val.status
      }
    },
    invoicePrinted(state, val) {
      let index = state.invoices.findIndex(order => order._id == val._id)
      if (index > -1) {
        state.invoices[index].printed = val.printed
        state.invoices[index].status = val.status
      }
    },
    invoiceEmailed(state, val) {
      let index = state.invoices.findIndex(invoice => invoice._id == val._id)
      if (index > -1) {
        state.invoices[index].emailed = val.emailed
        state.invoices[index].status = val.status
      }
    },
    completeItem(state, val) {
      if (state.orders) {
        for (let order of state.orders) {
          for (let item of order.items) {
            if (item.name == val.item.name && order.route == val.route) {
              item.status = 2
            }
          }
        }
      }
      let i = 0
      for (let items of state.pickItems) {
        if (items.item.name == val.item.name && items.route == val.route) {
          console.log("splice")
          state.pickItems.splice(i, 1)
        }
        i++
      }
    },
    completeOrder(state, val) {
      let len = state.pickupOrders.length
      for (let i = 0; i < len; i++) {
        if (state.pickupOrders[i]._id == val._id && state.pickupOrders[i].route == val.route) {
          state.pickupOrders.splice(i, 1)
        }
      }
      if (state.orders) {
        for (let order of state.orders) {
          if (order._id === val.order_id) {
            for (let item of order.items) {
              if (item._id === val._id) {
                item.amount = val.amount
                item.price = val.price
                item.subtotal = val.amount * val.price
                item.status = val.status
                item.unit = val.unit
                return
              }
            }
          }
        }
      }
    },
    refreshClients() {
      // localStorage.clear();
      location.reload();
    },
    sendMessage(state, val) {
      state.snack = { snackBar: true, info: true, snackText: val.message }
    },
    fetchVehicles(state, val) {
      state.vehicles = val
    },
    insertVehicle(state, val) {
      state.vehicles.push(val)
    },
    fetchDrivers(state, val) {
      state.drivers = val
    },
    insertDriver(state, val) {
      state.drivers.push(val)
    },
    insertLogistic(state, val) {
      let items = val.items.map(item => item.itemNumber)
      state.orders.filter(order => val.orders.includes(order.orderNumber)).forEach(order => {
        order.items.filter(item => items.includes(item.itemNumber)).forEach(item => item.assigned = true)
      })
      state.logistics.push(val)
    },
    pushLogisticItems(state, val) {
      let items = val.items.map(item => item.itemNumber)
      console.log(items)
      state.orders.filter(order => val.orders.includes(order.orderNumber)).forEach(order => {
        order.items.filter(item => items.includes(item.itemNumber)).forEach(item => item.assigned = true)
      })
      let index = state.logistics.findIndex(logistic => logistic.route == val.route)
      if (index > -1) {
        state.logistics[index].vehicle = val.vehicle
        state.logistics[index].driver = val.driver
        state.logistics[index].items.push(val.items)
      }
    },
    resetLogisticItems(state, val) {
      let date = new Date(val.date).toISOString()
      state.orders.filter(order => order.date === date && order.route === val.route).forEach(order => {
        order.items.filter(item => val.items.includes(item.itemNumber)).forEach(item => item.assigned = false)
      })
    },
    resetLogistic(state, val) {
      let items = val.items.flat().map(item => item.itemNumber)
      state.orders.filter(order => val.orders.includes(order.orderNumber)).forEach(order => {
        order.items.filter(item => items.includes(item.itemNumber)).forEach(item => item.assigned = false)
      })
    },
    appendLogisticItem(state, val) {
      let date = new Date(val.date).toISOString()
      state.orders.filter(order => order.date === date && order.route === val.route).forEach(order => order.items.filter(item => item.itemNumber == val.itemNumber).forEach(item => item.assigned = true))
    },
    prependLogisticItem(state, val) {
      let date = new Date(val.date).toISOString()
      state.orders.filter(order => order.date === date && order.route === val.route).forEach(order => order.items.filter(item => item.itemNumber == val.itemNumber).forEach(item => item.assigned = false))
    },
    insertPurchaseOrder(state, val) {
      state.purchaseOrders.push(val)
      for (let item of val.items) {
        let index = state.items.findIndex(i => i.itemNumber === item.itemNumber)
        if (index > -1) state.items[index].stock += item.stock
      }
    },
    incQuote: (state, val) => {
      for (let quote of state.quotes) {
        if (quote._id === val._id) {
          quote.status = val.status
          for (let item of quote.items) {
            if (item.status > -1 && item.status < val.status) item.status = val.status
          }
          return
        }
      }
    },
    insertQuote: (state, val) => {
      state.quotes.push(val)
    },
    updateQuote: (state, val) => {
      for (let quote of state.quotes) {
        if (quote._id === val._id) {
          Object.assign(quote, val)
          return
        }
      }
    },
    deleteQuote(state, val) {
      let i = 0
      let len = state.quotes.length
      for (i; i < len; i++) {
        if (state.quotes[i]._id == val) {
          state.quotes.splice(i, 1)
          return
        }
      }
    },
    quotePrinted(state, val) {
      let index = state.quotes.findIndex(quote => quote._id == val._id)
      if (index > -1) {
        state.quotes[index].printed = val.printed
        state.quotes[index].status = val.status
        state.quotes[index].items.forEach(item => item.status = val.status)
      }
    },
    quoteEmailed(state, val) {
      let index = state.quotes.findIndex(quote => quote._id == val._id)
      if (index > -1) {
        state.quotes[index].emailed = val.emailed
        state.quotes[index].status = val.status
      }
    },
    acceptQuote: (state, val) => {
      for (let quote of state.quotes) {
        if (quote._id === val._id) {
          quote.status = 2
          quote.signature = val.signature
          return
        }
      }
    },
    confirmTransaction: (state, val) => {
      for (let invoice of state.invoices) {
        if (invoice._id === val._id) {
          invoice.status = val.status
          invoice.credit = val.credit
          invoice.debit = val.debit
          invoice.payments = val.payments
          return
        }
      }
    },
    clientAlreadyConnected(state, val) {
      if (state.wsConnected) {
        alert("U bent al verbonden \nSluit a.u.b. dit venster")
        console.log(val)
      }
    },
    async setWsId(state, val) {
      state.wsId = val
    }
  },
  actions: {
    async fetchGET({ commit }, { type, data }) {
      try {
        let response = await fetchGET(type, data)
        commit(type, response)
        return response
      }
      catch (e) {
        console.error(e)
      }
    },
    async fetchPOST({ commit }, { type, data }) {
      try {
        let response = await fetchPOST(type, data)
        commit(type, response)
      }
      catch (e) {
        console.error(e)
      }
    },
    async loginWithCredentials({ commit, dispatch }, { username, password, keepAlive }) {
      try {
        let res = await fetch(`${config.api.host}/auth/login`, {
          method: "POST",
          body: JSON.stringify({
            username: username,
            password: password,
            keepAlive: keepAlive
          }),
          headers: {
            "Content-Type": "application/json"
          },
          credentials: "include"
        })

        let result = await res.json()
        if (!result.success) return result
        localStorage.setItem("token", result.token)
        await wsConnect()
        commit("setClient", result.client)
        commit("loggedIn", true)
        dispatch("fetchGET", { type: "settings", data: {} })
        return result
      }
      catch (e) {
        console.error(e)
      }
    },
    async loginWithToken({ commit, dispatch }) {
      try {
        let token = localStorage.getItem("token")
        if (token) {
          let res = await fetch(`${config.api.host}/auth/validateToken`, {
            method: "POST",
            body: JSON.stringify({ token: token }),
            headers: {
              "Content-Type": "application/json"
            },
            credentials: "include"
          })
          let result = await res.json()
          if (!result.success) throw "logout"
          await wsConnect()
          commit("setClient", result.client)
          commit("loggedIn", true)
          dispatch("fetchGET", { type: "settings", data: {} })
          return result
        }
      }
      catch (e) {
        console.error(e)
        dispatch("logout")
      }
    },
    async logout({ commit, state }) {
      try {
        let res = await fetch(`${config.api.host}/auth/logout`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          credentials: "include"
        })
        let result = await res.json()
        if (!result.success) throw "logout"
        localStorage.removeItem("token")
        commit("setClient", {})
        commit("loggedIn", false)
        if (state.ws) state.ws.close()
        commit("setWs", null)
        router.push("/login")
      }
      catch (e) {
        console.error(e)
      }
    },
    async logoutWithoutRedirect({ commit, state }) {
      try {
        let res = await fetch(`${config.api.host}/auth/logout`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          credentials: "include"
        })
        let result = await res.json()
        if (!result.success) throw "logout"
        localStorage.removeItem("token")
        commit("setClient", {})
        commit("loggedIn", false)
        if (state.ws) state.ws.close()
        commit("setWs", null)
        location.reload()
      }
      catch (e) {
        console.error(e)
      }
    }
  },
  getters: {
    loggedIn(state) {
      return function() {
        return state.loggedIn
      }
    },
    activeRoutes(state) {
      let map = state.orders.map(order => order.route)
      let set = new Set(map.sort())
      let routes = []
      set.forEach(route => {
        let obj = {
          route: route,
          length: state.orders.filter(order => order.route == route).length
        }
        routes.push(obj)
      })
      return routes
    },
    allRoutes(state) {
      let map = state.settings.routes
      let set = new Set(map.sort())
      let routes = []
      set.forEach(route => {
        let obj = {
          route: route,
          length: state.orders.filter(order => order.route == route).length
        }
        routes.push(obj)
      })
      return routes
    },
    sortedRoutes(state) {
      let routes = state.settings.routes.sort()
      return routes
    }
  }
})
